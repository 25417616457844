import moment from 'moment';
import axios from 'axios';
import config from "../config";
import { toast } from 'react-toastify';
import { listsData } from "../pages/remoteaudits/selectLists"
import i18n from 'i18next';
const findOption = listsData.findOption;

export const RECEIVED_BUILDING = 'RECEIVED_BUILDING';
export const RECEIVING_BUILDING = 'RECEIVING_BUILDING';

export const RECEIVED_BUILDINGS = 'RECEIVED_BUILDINGS';
export const RECEIVING_BUILDINGS = 'RECEIVING_BUILDINGS';

export const RECEIVED_BUILDING_LM = 'RECEIVED_BUILDING_LM';
export const RECEIVING_BUILDING_LM = 'RECEIVING_BUILDING_LM';

export const UPDATING_BUILDING = 'UPDATING_BUILDING';
export const UPDATED_BUILDING = 'UPDATED_BUILDING';
export const VA_STATUS_UPDATED = 'VA_STATUS_UPDATED';
export const UPDATED_REPORT_B64 = 'UPDATED_REPORT_B64';

export function getBuildingRequest(buildingId, isPublic) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveBuilding(''));
        } else {
            dispatch(receivingBuilding(buildingId));

            const process = (res) => {
                let typesOfHousesItem = listsData.list.typesOfHouses.filter(function (x) {
                    return x.value === (res.data.building.typeOfHouse || '').toLowerCase();
                });
                res.data.building.typeOfHouseSelected = typesOfHousesItem;
                let heatPumpSourceSupplyItem = listsData.list.heatPumpSourceSupply.filter(function (x) {
                    return x.value === (res.data.building.heatPumpSourceSupply || '').toLowerCase();
                });
                res.data.building.heatPumpSourceSupplySelected = heatPumpSourceSupplyItem;
                let primaryHotWaterEqTypeItem = listsData.list.primaryHotWaterEqType.filter(function (x) {
                    return x.value === (res.data.building.primaryHotWaterEquipmentType || '').toLowerCase()
                });
                res.data.building.primaryHotWaterEquipmentTypeSelected = primaryHotWaterEqTypeItem;
                let primaryHotWaterEqFuelTypeItem = listsData.list.primaryHotWaterEqFuelType.filter(function (x) {
                    return x.value === (res.data.building.primaryHotWaterEquipmentFuelType || '').toLowerCase()
                });
                res.data.building.primaryHotWaterEquipmentFuelTypeSelected = primaryHotWaterEqFuelTypeItem
                let houseStorysItem = listsData.list.houseStorys.filter(function (x) {
                    return Math.abs(parseFloat(x.value) - res.data.building.stories) < 0.01;
                });
                res.data.building.storiesSelected = houseStorysItem;
                let ventalationTypeItem = listsData.list.ventalationTypes.filter(function (x) {
                    return x.value === (res.data.building.ventalationType || '').toLowerCase()
                });
                res.data.building.ventalationTypeSelected = ventalationTypeItem;
                let secondaryDomesticHotWaterTypesItem = listsData.list.secondaryDomesticHotWaterTypes.filter(function (x) {
                    return x.value === (res.data.building.secondaryDomesticHotWaterType || '');
                });
                res.data.building.secondaryDomesticHotWaterTypeSelected = secondaryDomesticHotWaterTypesItem;
                let primataryHeatingTypesItem = listsData.list.primaryHeatingTypes.filter(function (x) {
                    return x.value === (res.data.building.furnaceType || '').toLowerCase()
                });
                res.data.building.primaryHeatingTypeSelected = primataryHeatingTypesItem;
                let houseTotOccupantsItem = listsData.list.houseTotOccupants.filter(function (x) {
                    return parseInt(x.value) === res.data.building.totalOccupants
                });
                res.data.building.totalOccupantsSelected = houseTotOccupantsItem;
                res.data.building.presenceOfPonyWallsSelected =
                    (res.data.building.presenceOfPonyWalls !== null ?
                        (res.data.building.presenceOfPonyWalls === true ?
                            { value: "Yes", label: "Yes", rating: 'good' }
                            : { value: "NO", label: "No", rating: 'good' })
                        : { value: "Select", label: "Select", rating: 'good' }
                    );
                let tempOfBasementItem = listsData.list.tempOfBasement.filter(function (x) {
                    return parseInt(x.value) === res.data.building.temperatureOfBasement
                });
                res.data.building.temperatureOfBasementSelected = tempOfBasementItem;
                let tempOfMainFloorItem = listsData.list.tempOfMainFloor.filter(function (x) {
                    return parseInt(x.value) === res.data.building.temperatureOfMainFloor
                });
                res.data.building.temperatureOfMainFloorSelected = tempOfMainFloorItem;
                let firePlaceDamperItem = listsData.list.firePlaceDamper.filter(function (x) {
                    return x.value === (res.data.building.firePlaceDamper || '').toLowerCase()
                });
                res.data.building.firePlaceDamperSelected = firePlaceDamperItem;
                let typeOfACSystemItem = listsData.list.typeOfACSystem.filter(function (x) {
                    return x.value === (res.data.building.typeOFACSystem || '').toLowerCase()
                });
                res.data.building.typeOfACSystemSelected = typeOfACSystemItem;
                let suplimentaryHeating1Item = listsData.list.suplimentaryHeating1.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem1 || '').toLowerCase()
                });
                res.data.building.supplementaryHeatingSystem1Selected = suplimentaryHeating1Item;
                if (['softwood', 'hardwood'].indexOf(res.data.building.supplementaryHeatingSystem1Fuel) >= 0) {
                    res.data.building.supplementaryHeatingSystem1Fuel = 'mixed wood';
                }
                let suplimentaryHeating1FuelItem = listsData.list.suplimentaryHeating1Fuel.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem1Fuel || '').toLowerCase();
                });
                res.data.building.supplementaryHeatingSystem1FuelSelected = suplimentaryHeating1FuelItem;
                let suplimentaryHeating2Item = listsData.list.suplimentaryHeating2.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem2 || '').toLowerCase();
                });
                res.data.building.supplementaryHeatingSystem2Selected = suplimentaryHeating2Item;
                if (['softwood', 'hardwood'].indexOf(res.data.building.supplementaryHeatingSystem2Fuel) >= 0) {
                    res.data.building.supplementaryHeatingSystem2Fuel = 'mixed wood';
                }
                let suplimentaryHeating2FuelItem = listsData.list.suplimentaryHeating2Fuel.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem2Fuel || '').toLowerCase();
                });

                res.data.building.supplementaryHeatingSystem2FuelSelected = suplimentaryHeating2FuelItem;
                res.data.building.v2DraftinessSelected = findOption(listsData.list.v2Draftiness, res.data.building.v2Draftiness);
                res.data.building.v2PrimaryHeatingSystemFuelTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemFuelType, res.data.building.v2PrimaryHeatingSystemFuelType);
                res.data.building.v2PrimaryHeatingSystemHeatpumpSourceSelected = findOption(listsData.list.v2PrimaryHeatingSystemHeatpumpSource, res.data.building.v2PrimaryHeatingSystemHeatpumpSource);
                res.data.building.v2PrimaryHeatingSystemHeatpumpTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemHeatpumpType, res.data.building.v2PrimaryHeatingSystemHeatpumpType);
                res.data.building.v2PrimaryHeatingSystemTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemType, res.data.building.v2PrimaryHeatingSystemType);
                res.data.building.v2SecondaryHeatingSystemFuelTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemFuelType, res.data.building.v2SecondaryHeatingSystemFuelType);
                res.data.building.v2SecondaryHeatingSystemHeatpumpSourceSelected = findOption(listsData.list.v2SecondaryHeatingSystemHeatpumpSource, res.data.building.v2SecondaryHeatingSystemHeatpumpSource);
                res.data.building.v2SecondaryHeatingSystemHeatpumpTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemHeatpumpType, res.data.building.v2SecondaryHeatingSystemHeatpumpType);
                res.data.building.v2SecondaryHeatingSystemTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemType, res.data.building.v2SecondaryHeatingSystemType);
                res.data.building.v2WaterHeatingSystemFuelTypeSelected = findOption(listsData.list.v2WaterHeatingSystemFuelType, res.data.building.v2WaterHeatingSystemFuelType);
                res.data.building.v2WaterHeatingSystemTypeSelected = findOption(listsData.list.v2WaterHeatingSystemType, res.data.building.v2WaterHeatingSystemType);
                res.data.building.v2OwnHomeSelected = findOption(listsData.list.v2OwnHome, res.data.building.v2OwnHome);

                res.data.building.hs_upgrade_json = res.data.building.hs_upgrade_json ?? JSON.stringify({
                    base_heating_system_type: 'electric baseboard',
                    base_heating_system_fuel_type: 'electricity',
                    base_heating_system_efficiency: 100,
                    base_heating_system_capacity: res.data.building.v2PrimaryHeatingSystemFurnaceCapacity
                });

                if (res.data.building.hs_upgrade_json) {
                    let json = JSON.parse(res.data.building.hs_upgrade_json);
                    json.base_heating_system_capacity = json.base_heating_system_capacity ?? res.data.building.v2PrimaryHeatingSystemFurnaceCapacity;
                    res.data.building.hs_upgrade_json = JSON.stringify(json);
                }

                dispatch(receiveBuilding(res.data));
            };

            return axios.get('/buildings/' + buildingId).then(process);                
        };
    };
}

export function getBuildingsRequest() {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveBuildings(''));
        } else {
            dispatch(receivingBuildings());
            return axios.get('/buildings/').then(res => {

                dispatch(receiveBuildings(res.data));
            })
        }
        ;

    };
}

export function getBuildingRequestLM(buildingId, isPublic) {

    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveBuildingLM(''));
        } else {
            dispatch(receivingBuildingLM());
            const lang = window.localStorage.getItem('i18nextLng') || 'en';
            const process = (res) => {
                if (res.data && res.data.data) {
                    res.data.data.LPU = res.data.dataLPU || null;
                    res.data.data.LPRE = res.data.dataLPRE || null;
                    res.data.data.LPUB = res.data.dataLPUB || null;
                    res.data.data.lastPublished = res.data.lastPublished || null;
                    res.data.data.confidenceScore = res.data.confidenceScore || 0;
                }
                if (buildingId && buildingId !== 'mock-user-123' && !isPublic) {
                    dispatch(receiveBuildingLM(res.data.data));
                }
                else {
                    dispatch(receiveBuildingLM(res.data.data));
                }
            };
            return axios.get('/buildings/' + buildingId + '/latest-model/?lang=' + lang).then(process);
        }
    };
}

export function updateCustomerRequest(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());
        axios.put('/buildings/' + building.buildingId + '/update-customer', building).then(res => {
            if (res.data.success) {
                dispatch(updatedBuilding({ buildingId: building.buildingId }));
                dispatch(getBuildingRequest(building.buildingId));
                toast.success("Customer info has been Updated!");
                window._RA_MADE_CHANGES = false;
            } else {
                dispatch(updatedBuilding({ buildingId: building.buildingId }));
                dispatch(getBuildingRequest(building.buildingId));
                toast.error("Failed to update customer info.");
            }
        })
    };
};

export function uploadEnerGuidePDF(file, building_id, callbacks) {
    return (dispatch) => {
        dispatch(vaStatusUpdate(0));
        dispatch(updatingBuilding());
        const formData = new FormData();
        formData.append("file", file);
        return axios.post(`/buildings/${building_id}/energuide-pdf-upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (ev) => {
                let total = ev.total;
                let percent = (ev.loaded * 100) / total;
                callbacks.progress(percent, total);
            }
        }).then(res => {
            axios.post('/buildings/update-base-model', {building_id, non_survey_update: true})
			.then(() => {
                dispatch(getBuildingRequest(building_id)).then(() => {
                    dispatch(getBuildingRequestLM(building_id)).then(() => {
                        toast.success("EnerGuide Report uploaded.");
                        dispatch(vaStatusUpdate(undefined));
                        dispatch(updatedBuilding(building_id));
                        callbacks.success(res);
                    });
                });
            });
        }).catch(err => {
            callbacks.error(err);
            toast.error("Error uploading EnerGuide Report");
            dispatch(vaStatusUpdate(undefined));
        });
    };
};

export function deleteEnerGuidePDF(building_id) {
    return (dispatch) => {
      return axios.delete(`/buildings/${building_id}/energuide-pdf-delete`).then(res => {
        dispatch(getBuildingRequest(building_id)).then(() => {
            dispatch(getBuildingRequestLM(building_id)).then(() => {
                toast.success("EnerGuide Report deleted.");
            });
        });
      }).catch(err => {
        toast.error("Error deleting EnerGuide Report");
      });
    };
};  

export function publishReportRequest(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());

        return axios({
            url: `/buildings/${building.buildingId}/get-latest-report`,
            method: 'GET',
            responseType: 'arraybuffer'
        }).then(res => {
            const form = new FormData();
            form.append('file', new Blob([res.data]), `Remote Audit Report - ${building.buildingId} - ${moment().format('YYYY-MM-DD HHmmSS')}.pdf`);
            axios.put('/buildings/' + building.buildingId + '/publish-report', form).then(res => {
                if (res.data.success) {
                    dispatch(getBuildingRequest(building.buildingId)).then(() => {
                        dispatch(getBuildingRequestLM(building.buildingId)).then(() => {
                            dispatch(vaStatusUpdate(undefined));
                            dispatch(updatedBuilding(building.buildingId));
                        });
                    });
                    toast.success("Report has been published!");
                    window._RA_MADE_CHANGES = false;
                } else {
                    dispatch(getBuildingRequest(building.buildingId)).then(() => {
                        dispatch(getBuildingRequestLM(building.buildingId)).then(() => {
                            dispatch(vaStatusUpdate(undefined));
                            dispatch(updatedBuilding(building.buildingId));
                        });
                    });
                    toast.error("Failed to publish reporo.");
                }
            })
        });
    };
}

export function buildingWizardRequest(building, direction, data) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());
        if (building.upgradeScenariosChecked) {
            dispatch(updatedBuilding({
                buildingId: building.buildingId,
                upgradeScenariosChecked: building.upgradeScenariosChecked
            }));
        }

        return axios({
            url: `/buildings/${building.buildingId}/update-status/${direction}`,
            method: 'PUT',
            data: data
        }).then(res => {
            dispatch(getBuildingRequest(building.buildingId)).then(() => {
                dispatch(getBuildingRequestLM(building.buildingId)).then(() => {
                    dispatch(vaStatusUpdate(undefined));
                    dispatch(updatedBuilding(building.buildingId));
                    toast.success(i18n.t("buildingUpdated"));
                });
            });
        });
    };
}

export function homeownerAction(buildingId, data) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());

        return axios({
            url: `/buildings/${buildingId}/homeowner-action`,
            method: 'PUT',
            data: data
        }).then(res => {
            dispatch(getBuildingRequest(buildingId)).then(() => {
                dispatch(getBuildingRequestLM(buildingId)).then(() => {
                    dispatch(vaStatusUpdate(undefined));
                    dispatch(updatedBuilding(buildingId));
                });
            });
        });
    };
}

export function homeownerDontShowIntro(homeownerAccountId, buildingId) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());

        return axios({
            url: `/buildings/${homeownerAccountId}/homeowner-dont-show-intro-again`,
            method: 'PUT',
            data: {}
        }).then(res => {
            dispatch(getBuildingRequest(buildingId)).then(() => {
                dispatch(getBuildingRequestLM(buildingId)).then(() => {
                    dispatch(vaStatusUpdate(undefined));
                    dispatch(updatedBuilding(buildingId));
                });
            });
        });
    };
}

export function homeownerViewedReport(buildingId) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());

        return axios({
            url: `/buildings/${buildingId}/homeowner-viewed-report`,
            method: 'PUT'
        }).then(res => {
            dispatch(getBuildingRequest(buildingId)).then(() => {
                dispatch(getBuildingRequestLM(buildingId)).then(() => {
                    dispatch(vaStatusUpdate(undefined));
                    dispatch(updatedBuilding(buildingId));
                });
            });
        });
    };
}

export function updateBuildingRequest(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        if (building.upgradeScenariosChecked) {
            dispatch(updatedBuilding({
                buildingId: building.buildingId,
                upgradeScenariosChecked: building.upgradeScenariosChecked
            }));
        }

        dispatch(updatingBuilding());
        dispatch(vaStatusUpdate(0));
        axios.put('/buildings/' + building.buildingId + '/' + (building.run_model_type || 'pre-upgrade'), building).then(res => {
            let checkUrl = `/buildings/${building.buildingId}/check-job/${res.data.job_id}`;
            let check = () => {

                axios.put(checkUrl, {}).then(res => {
                    if (res.data.res) {
                        dispatch(getBuildingRequest(building.buildingId)).then(() => {
                            dispatch(getBuildingRequestLM(building.buildingId)).then(() => {
                                dispatch(vaStatusUpdate(undefined));
                                dispatch(updatedBuilding(building.buildingId));
                            });
                        });
                        toast.success("Building has been Updated!");
                        window._RA_MADE_CHANGES = false;
                    } else if (res.data.error) {
                        dispatch(vaStatusUpdate(undefined));
                    } else {
                        dispatch(vaStatusUpdate(res.data.status));
                        setTimeout(check, 1000);
                    }
                });
            };
            check();
        })
    };
}

export function updateBuildingAttachments(buildingId, attachments) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());
        dispatch(vaStatusUpdate(0));
        axios.put('/buildings/' + buildingId + '/attachments', { attachment_id: attachments }).then(res => {
            dispatch(updatedBuilding(buildingId));
            dispatch(vaStatusUpdate(undefined));
        });
    };
}

export function updateAuditorNotesRequest(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        axios.put('/buildings/' + building.buildingId + '/', building).then(res => {
            if (res.data.res) {
                toast.success("Notes have been saved!");
            }
        });
    };
}

export function updateCustomerPrioritiesRequest(buildingId, priorities) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        return axios.put('/buildings/' + buildingId + '/priorities', { priorities }).then(res => {
            if (res.data.res) {
                toast.success("Priorities have been saved!");
                dispatch(getBuildingRequest(buildingId)).then(() => {
                    dispatch(getBuildingRequestLM(buildingId)).then(() => {
                        dispatch(updatedBuilding(buildingId));
                    });
                });
            }
        });
    };
}

export function publishAndDownloadReportRequest(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());

        return axios({
            url: `/buildings/${building.buildingId}/get-latest-report`,
            method: 'GET',
            responseType: 'arraybuffer'
        }).then(res => {
            const form = new FormData();
            form.append('file', new Blob([res.data]), `Remote Audit Report - ${building.buildingId} - ${moment().format('YYYY-MM-DD HHmmSS')}.pdf`);
            axios.put('/buildings/' + building.buildingId + '/publish-report', form).then(res => {
                if (res.data.success) {
                    dispatch(getBuildingRequest(building.buildingId)).then(() => {
                        dispatch(getBuildingRequestLM(building.buildingId)).then(() => {
                            dispatch(updatedBuilding(building.buildingId));
                            return axios({
                                url: `/buildings/${building.buildingId}/get-published-report`,
                                method: 'GET',
                                responseType: 'json'
                            }).then(res => {
                                const link = document.createElement('a');
                                link.href = res.data.url;
                                let tok = res.data.url.split('/');
                                link.setAttribute('download', `${tok[tok.length - 1]}`);
                                document.body.appendChild(link);
                                link.click();
                                dispatch(vaStatusUpdate(undefined));
                            });
                        });
                    });
                    toast.success("Report has been published!");
                    window._RA_MADE_CHANGES = false;
                } else {
                    dispatch(getBuildingRequest(building.buildingId)).then(() => {
                        dispatch(getBuildingRequestLM(building.buildingId)).then(() => {
                            dispatch(vaStatusUpdate(undefined));
                            dispatch(updatedBuilding(building.buildingId));
                        });
                    });
                    toast.error("Failed to publish report.");
                }
            })
        });
    };
}

export function downloadReportRequest(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(vaStatusUpdate(0));
        if (building.downloadPublished) {
            return axios({
                url: `/buildings/${building.buildingId}/get-published-report`,
                method: 'GET',
                responseType: 'json'
            }).then(res => {
                const link = document.createElement('a');
                link.href = res.data.url;
                let tok = res.data.url.split('/');
                link.setAttribute('download', `${tok[tok.length - 1]}`);
                document.body.appendChild(link);
                link.click();
                dispatch(vaStatusUpdate(undefined));
                toast.success("Report has been Generated!");
            });
        }
        else {
            axios({
                url: `/buildings/${building.buildingId}/get-latest-report${building.downloadType1 ? '/?type1=1' : (building.downloadPublished ? '/?published=1' : '')}`,
                method: 'GET',
                responseType: 'blob'
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${building.buildingId}${building.downloadType1 ? '-type-1' : ''}.pdf`);
                document.body.appendChild(link);
                link.click();
                dispatch(vaStatusUpdate(undefined));
                toast.success("Report has been Generated!");
            });
        }
    };
}

export function hvacTestRequest(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        if (building.upgradeScenariosChecked) {
            dispatch(updatedBuilding({
                buildingId: building.buildingId,
                upgradeScenariosChecked: building.upgradeScenariosChecked
            }));
        }

        dispatch(updatingBuilding());
        dispatch(vaStatusUpdate(0));
        axios.get('/buildings/' + building.buildingId + '/hvac-test', building).then(res => {
            let checkUrl = `/buildings/${building.buildingId}/check-job/${res.data.job_id}`;
            let check = () => {
                axios.put(checkUrl, {}).then(res => {
                    if (res.data.res) {
                        toast.success("Test complete.");
                        dispatch(vaStatusUpdate(undefined));
                        dispatch(updatedBuilding(building));

                        let rows = [];
                        rows.push(`"Pre-Upgrade HSPF","Post-Upgrade HSPF","Pre-Upgrade Heating Capacity BTU/hr","Post-Upgrade Heating Capacity BTU/hr","Estimated Heating Savings GJ/year"`);
                        for (let i = 0; i < res.data.res.length; i++) {
                            let r = res.data.res[i];
                            rows.push(`${Math.round(r.preHSPF * 10000) / 10000},${Math.round(r.postHSPF * 10000) / 10000},${Math.round(r.preHPCAP)},${Math.round(r.postHPCAP)},${Math.round(r.heatpumpSavings * 1000) / 1000}`);
                        }
                        let csvStr = rows.join('\n');

                        let blob = new Blob([csvStr], { type: "data:application/octet-stream;base64" });
                        let url = window.URL.createObjectURL(blob);
                        let link = document.createElement("a");
                        link.setAttribute("href", url);
                        link.setAttribute("download", `hvac-test-${building.buildingId}.csv`);
                        link.style.display = 'none';
                        document.body.appendChild(link);

                        link.click();
                        document.body.removeChild(link);
                    } else if (res.data.error) {
                        dispatch(vaStatusUpdate(undefined));
                    } else {
                        dispatch(vaStatusUpdate(res.data.status));
                        setTimeout(check, 1000);
                    }
                });
            };
            check();
        })
    };
}

let _LATEST_GET_REPORT = 0;

export function getReportBase64(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        _LATEST_GET_REPORT += 1;

        let seq = _LATEST_GET_REPORT;

        dispatch(updatedReportB64(building.buildingId, null));

        axios({
            url: `/buildings/${building.buildingId}/get-latest-report`,
            method: 'GET',
            responseType: 'arraybuffer'
        }).then(res => {
            if (seq < _LATEST_GET_REPORT) {
                return;
            }
            let b64encoded = btoa([].reduce.call(new Uint8Array(res.data), function (p, c) {
                return p + String.fromCharCode(c)
            }, ''));
            dispatch(updatedReportB64(building.buildingId, b64encoded));
        });
    };
}

export function resetBuildingType1Request(building) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        dispatch(updatingBuilding());
        axios.put('/buildings/' + building.buildingId + '/reset-to-type-1', building).then(res => {
            dispatch(getBuildingRequestLM(building.buildingId));
            dispatch(getBuildingRequest(building.buildingId));
            dispatch(updatedBuilding(res.data));
            toast.success("Building has been Updated!");
        })
    };
}

export function updateReportRequest(building, isQuickSave, isPublish) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;

        if (!isQuickSave) {
            dispatch(updatedReportB64(building.buildingId, null));
            dispatch(updatingBuilding());
        }
        axios.put('/buildings/' + building.buildingId + '/update-report', building).then(res => {
            dispatch(getBuildingRequestLM(building.buildingId));
            dispatch(updatedBuilding(res.data));
            if (!isQuickSave) {
                dispatch(getReportBase64(building));
            }
            if (!isPublish) {
                toast.success(isQuickSave ? "Changes have been saved!" : "Report has been Updated!");
            }
            else {
                dispatch(updatingBuilding());
            }
            window._RA_MADE_CHANGES = false;
        })
    };
}

export function receiveBuilding(payload) {
    return {
        type: RECEIVED_BUILDING,
        payload
    }
}

export function receivingBuilding() {
    return {
        type: RECEIVING_BUILDING
    }
}

export function receiveBuildings(payload) {
    return {
        type: RECEIVED_BUILDINGS,
        payload
    }
}

export function receivingBuildings() {
    return {
        type: RECEIVING_BUILDINGS
    }
}

export function receiveBuildingLM(payload) {
    return {
        type: RECEIVED_BUILDING_LM,
        payload
    }
}

export function receivingBuildingLM() {
    return {
        type: RECEIVING_BUILDING_LM
    }
}


export function updatingBuilding() {
    return {
        type: UPDATING_BUILDING
    }
}

export function updatedBuilding(payload) {
    return {
        type: UPDATED_BUILDING,
        payload
    }
}

export function updatedReportB64(id, payload) {
    return {
        type: UPDATED_REPORT_B64,
        payload: {
            id: id,
            base64_report: payload
        }
    }
}

export function vaStatusUpdate(status) {
    return {
        type: VA_STATUS_UPDATED,
        payload: {
            status: status
        }
    };
}
