import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonToolbar, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Widget from "../../components/Widget/Widget";
import s from "./EnergyAdvisor.module.scss";
import { getBuildingsRequest } from "../../actions/buildings";
import axios from 'axios';
import SimpHoverIcon from './components/HoverIcon';
import "./EnergyAdvisor.scss";
import { Trans, withTranslation } from "react-i18next";
import { stat } from "fs";

class EnergyAdvisor extends React.Component {
	static propTypes = {
		customers: PropTypes.array,
		dispatch: PropTypes.func.isRequired,
		buildings: PropTypes.object,
	};
	static defaultProps = {
		customers: [
			{
				buildingId: "130801",
				yearBuilt: 2004,
				furnaceType: null,
			},
		],
	};

	state = {
		popovers: {},
		promoAlert: false,
		filter: null,
	};


	constructor() {
		super();
		this.apiFormatter = this.apiFormatter.bind(this);
		this.statusFormatter = this.statusFormatter.bind(this);
	}
	componentDidMount() {
		this.props.dispatch(getBuildingsRequest());
		setTimeout(() => {
			this.showPromoAlert();
		}, 100);
		if (this.resizeEvent) {
			window.removeEventListener("resize", this.resizeEvent);
			this.resizeEvent = null;
		}
		window.addEventListener("resize", this.resizeEvent = (() => {this.setState({rand: Math.random()})}));
	}

	componentWillUnmount() {
		if (this.resizeEvent) {
			window.removeEventListener("resize", this.resizeEvent);
			this.resizeEvent = null;
		}
	}

	showPromoAlert() {
		this.setState({ promoAlert: true });
	}

	imageFormatter(cell) {
		return <img src={cell} alt="..." className={s.image} title="image" />;
	}

	statusFormatter(cell) {
		return <div>
			<b>{cell.split(":")[0] || ''}</b><br/>
			<span style={{fontSize: "0.9em"}}>{cell.split(":")[1] || ''}</span>
		</div>;
	}

	priceFormatter = (cell) => {
		return <span className="text-success">{cell}</span>;
	};

	titleFormatter(cell, row) {
		return cell ? (
			<Link className="text-success" to={"/app/main/energy-advisor/home/" + cell[0].toUpperCase() + cell.slice(1)}>
				{cell[0].toUpperCase() + cell.slice(1)}
			</Link>
		) : (
			""
		);
	}

	togglePopover(id) {
		let newState = { ...this.state };
		if (!this.state.popovers[id]) {
			newState.popovers[id] = true;
		} else {
			newState.popovers[id] = !newState.popovers[id];
		}
		this.setState(newState);
	}

	nameFormatter(cell, row) {
		const { t } = this.props;
		const daysDiff = row.isUnresponsive ? moment().diff(moment(row.isUnresponsive), 'days') : -1;
		let ago = `${daysDiff} days ago`;
		if (daysDiff < 1) {
			ago = 'today';
		}
		else if (daysDiff < 2) {
			ago = 'yesterday';
		}
		return (
			<div>
				{row.isUnresponsive && (
					<SimpHoverIcon icon="/images/unresponsive.svg">
						<div className="unresponsive-bubble-top">
							<Trans i18nKey="homeownerUnresponsive" />
						</div>
						<div className="unresponsive-bubble-bottom">
							<Trans
								i18nKey="markedUnresponsive"
								values={{ ago }}
							/>
						</div>
					</SimpHoverIcon>
				)}
				{cell}
			</div>
		);
	}

	createdAtFormatter(cell) {
		return (<div>{moment(cell).format('MMM. D, YYYY')}</div>);
	}

	apiFormatter(cell, row) {
		const { t } = this.props;
		return (
			<ButtonToolbar>
				<Button color="primary" size="xs" onClick={() => this.props.history.push("/app/main/energy-advisor/home/" + row.buildingId)}>
					<span className="d-none d-md-inline-block">
						{this.props.currentUser && this.props.currentUser.role === "helpdesk" ? t("viewReport") : t("audit")}
					</span>
					<span className="d-md-none">
						<i className="la la-edit" />
					</span>
				</Button>
				{false && <Button color="primary" size="xs" onClick={() => this.props.history.push("/app/main/energy-buildings-project/" + row.buildingId)}>
					<span className="d-none d-md-inline-block">{t('files')}</span>
					<span className="d-md-none">
						<i className="la la-edit" />
					</span>
				</Button>}
				{false && row.status != 0 && row.status != 2 && <Button color="primary" size="xs" onClick={() => this.exportH2K(row.buildingId, `${row.name || row.address} - ${moment().format()}`)}>
					<span className="d-none d-md-inline-block">{t('h2kDownload')}</span>
				</Button>}
			</ButtonToolbar>
		);
	}

	renderSizePerPageDropDown = (props) => {
		const limits = [];
		props.sizePerPageList.forEach((limit) => {
			limits.push(
				<DropdownItem key={limit} onClick={() => props.changeSizePerPage(limit)}>
					{limit}
				</DropdownItem>
			);
		});
		return (
			<Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
				<DropdownToggle color="subtle-blue" caret>
					{props.currSizePerPage}
				</DropdownToggle>
				<DropdownMenu>{limits}</DropdownMenu>
			</Dropdown>
		);
	};

	exportTable(data) {
		let rows = [];
		let header = [
			'"Building ID"',
			'"Name"',
			'"Address"',
			'"City"',
			'"Postal Code"',
			'"Year Built"',
			'"Type of House"',
			'"Audit Status"',
			'"Last Published By"',
			'"Last Published On"',
			'"Onboarded On"',
			'"Salesforce ID"',
			'"User Building ID"',
			'"User Email"',
			'"User Pin"',
			'"User Group"',
			'"No. of Recommendations in Automated Audit"'
		];
		let headerKeys = [
			"buildingId",
			"fullName",
			"address",
			"city",
			"postal_code",
			"yearBuilt",
			"typeOfHouse",
			"statusText",
			"last_published_fullName",
			"last_published_timestamp",
			"createdAt",
			"salesforceId",
			"customer_id",
			"emailAddress",
			"customerPin",
			"customerUserGroup",
			"numRecommendationsAutomatedVA"
		];
		// if( this.props.currentUser.role.includes('remoteauditor')){
		// 	header = header.slice(0, -7);
		// 	headerKeys = headerKeys.slice(0, -7);
		// }
		rows.push(header.join(","));
		for (let R of data) {
			let row = [];
			for (let i = 0; i < headerKeys.length; i++) {
				let val = null;
				try {
					val = R[headerKeys[i]];
				} catch (err) {
					val = null;
				}
				if (typeof val === "string") {
					if (headerKeys[i] === "last_published_timestamp" || headerKeys[i] === "createdAt") {
						val = moment(val).format("YYYY-MM-DD HH:mm");
					}
					row.push(JSON.stringify(val));
				} else if (typeof val === "number") {
					if (val === Math.floor(val)) {
						row.push(`${val}`);
					} else {
						row.push(`${val.toFixed(3)}`);
					}
				} else if (typeof val === "boolean") {
					if (val) {
						row.push(`TRUE`);
					} else {
						row.push(`FALSE`);
					}
				} else {
					row.push(``);
				}
			}
			rows.push(row.join(","));
		}

		let csvStr = rows.join("\n");

		let blob = new Blob([csvStr], { type: "data:application/octet-stream;base64" });
		let url = window.URL.createObjectURL(blob);
		let link = document.createElement("a");
		link.setAttribute("href", url);
		link.setAttribute("download", `Customer Data - ${moment().format("YYYY-MM-DD HHmmSS")}.csv`);
		link.style.display = "none";
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	}

	exportH2K(buildingId, fileName) {
		axios.get('/buildings/' + buildingId + '/latest-h2k', {}).then(async (res) => {
			let blob = new Blob([res.data], { type: "data:application/octet-stream;base64" });
			let url = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			link.setAttribute("href", url);
			link.setAttribute("download", `${fileName}.h2k`);
			link.style.display = "none";
			document.body.appendChild(link);

			link.click();
			document.body.removeChild(link);
		});
	}

	countBuildingsAwaiting() {
		let num = this.props.customers.length;
		let count = 0;
		for (let i = 0; i < num; i++) {
			const temp = this.props.customers[i];
			if (temp.highestAuditStatus >= 2 && temp.highestAuditStatus != 3 && (temp.highestAuditSubStatus || 1) <= 1 && temp.isUnresponsive == null) {
				count++;
			}
		}
		return count;
	}

	countBuildingsBooked() {
		let num = this.props.customers.length;
		let count = 0;
		for (let i = 0; i < num; i++) {
			const temp = this.props.customers[i];
			if ((temp.highestAuditStatus == 2 || temp.highestAuditStatus == 4) && (temp.highestAuditSubStatus == 2) && temp.isUnresponsive == null) {
				
				count++;
			}
		}
		return count;

	}

	countBuildingsInProg() {
		let num = this.props.customers.length;
		let count = 0;
		for (let i = 0; i < num; i++) {
			const temp = this.props.customers[i];
			if ((temp.highestAuditStatus == 2 || temp.highestAuditStatus == 4) && (temp.highestAuditSubStatus > 2 && temp.highestAuditSubStatus < 6) && temp.isUnresponsive == null) {
				count++;
			}
		}
		return count;

	}
	countBuildingsPublished() {
		let num = this.props.customers.length;
		let count = 0;
		for (let i = 0; i < num; i++) {
			const temp = this.props.customers[i];
			if ((temp.highestAuditStatus == 2 || temp.highestAuditStatus == 4) && (temp.highestAuditSubStatus == 6) && temp.isUnresponsive == null) {
				count++;
			}
		}
		return count;

	}

	countPreUpgrade(mainStatusNum) {
		let count = 0;
		if (mainStatusNum == 0) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.customers[i];
				if ((temp.highestAuditSubStatus || 1) <= 1 && (temp.highestAuditStatus || 1) == 2 && temp.isUnresponsive == null) {
					count++;
				}
			}
		}
		else if (mainStatusNum == 1) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.customers[i];
				if (temp.highestAuditSubStatus == 2 && (temp.highestAuditStatus || 1) <= 2 && temp.isUnresponsive == null) {
					count++;
				}
			}
		}
		else if (mainStatusNum == 2) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.customers[i];
				if (temp.highestAuditSubStatus > 2 && temp.highestAuditSubStatus < 6 && (temp.highestAuditStatus || 1) <= 2 && temp.isUnresponsive == null) {
					count++;
				}
			}
		}
		else if (mainStatusNum == 3) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.customers[i];
				if (temp.highestAuditSubStatus == 6 && (temp.highestAuditStatus || 1) <= 2 && temp.isUnresponsive == null) {
					count++;
				}
			}
		}

		return count;
	}

	countPostUpgrade(mainStatusNum) {
		let count = 0;
		if (mainStatusNum == 0) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.buildings[i];
				if ((temp.highestAuditSubStatus || 1) <= 1 && (temp.highestAuditStatus == 4) && temp.isUnresponsive == null) {
					count++;
				}
			}
		}
		else if (mainStatusNum == 1) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.buildings[i];
				if (temp.highestAuditSubStatus == 2 && temp.highestAuditStatus == 4 && temp.isUnresponsive == null) {
					count++;
				}
			}
		}
		else if (mainStatusNum == 2) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.buildings[i];
				if (temp.highestAuditSubStatus > 2 && temp.highestAuditSubStatus < 6 && temp.highestAuditStatus == 4 && temp.isUnresponsive == null) {
					count++;
				}
			}
		}
		else if (mainStatusNum == 3) {
			let num = this.props.customers.length;
			for (let i = 0; i < num; i++) {
				const temp = this.props.buildings[i];
				if (temp.highestAuditSubStatus == 6 && temp.highestAuditStatus == 4 && temp.isUnresponsive == null)  {
					count++;
				}
			}
		}
		return count;
	}

	filterCustomers(status) {
		let arr = [];
		for (let i = 0; i < this.props.customers.length; i++) {
			if (status == 'awaiting booking') {
				const temp = this.props.customers[i];
				if (temp.statusText == 'Pre-Upgrade Audit: Awaiting Booking' || temp.statusText == 'Post-Upgrade Audit: Awaiting Booking') {
					arr.push(temp);
				}
			}
			if (status == 'awaiting audit') {
				const temp = this.props.customers[i];
				if (temp.statusText == 'Pre-Upgrade Audit: Awaiting Audit' || temp.statusText == 'Post-Upgrade Audit: Awaiting Audit') {
					arr.push(temp);
				}
			}
			if (status == 'in-progress') {
				const temp = this.props.customers[i];
				if (temp.statusText == 'Pre-Upgrade Audit: Audit Started' || temp.statusText == 'Post-Upgrade Audit: Audit Started'|| temp.statusText == 'Pre-Upgrade Audit: Upgrades Modelled' || temp.statusText == 'Pre-Upgrade Audit: Baseline Modelled' || temp.statusText == 'Post-Upgrade Audit: Upgrades Modelled' || temp.statusText == 'Post-Upgrade Audit: Baseline Modelled') {
					arr.push(temp);
				}
			}
			if (status == 'published') {
				const temp = this.props.customers[i];
				if (temp.statusText == 'Post-Upgrade Audit: Published' || temp.statusText == 'Pre-Upgrade Audit: Published' || temp.statusText == 'Published') {
					arr.push(temp);
				}
			}
			if (status == null) {
				arr.push(this.props.customers[i]);
			}
		}
		return arr;
	}

	clickHandler(filter) {
		if (filter == this.state.filter) {
			this.setState({ filter: null });
		}
		else {
			this.setState({ filter: filter });
		}

	}


	render() {
		const options = {
			sizePerPage: 10,
			paginationSize: 3,
			sizePerPageDropDown: this.renderSizePerPageDropDown,
		};
		const { t } = this.props;
		const formatDate = (value, row, index) => (value ? moment(value).format("MMM. D, YYYY") : "");
		const customerList = this.filterCustomers(this.state.filter).map((customer, index) => {
			return {
				...customer,
				statusText: t(customer.statusText)}
		});
		return (
			<div>
				<div className="page-top-line">
					{!window.IS_SASK_POWER && <h1 className="header" style={{ width: "100%", marginTop: "0px", marginLeft: "0px", color: "#fff", fontSize: "26px", fontWeight: "bolder" }}>
						{t('overview')}
						{/*<span className="text" style={{ width: "100%", marginTop: "0px", marginLeft: "30px", color: "#CCC", fontSize: "14px", textAlign: "center" }}>
							Click status below to filter table
						</span>*/}
					</h1>}

					{window.IS_E1_ORG && !window.IS_SASK_POWER && <div className="row" style={{ width: "100%", marginTop: "10px" }}>

						<div className={"status-header-container-button" + (this.state.filter == 'awaiting booking' ? " status-header-container-button-selected" : "")} onClick={() => this.clickHandler('awaiting booking')}>

							<div>

								<div className="sh-pre-upgrade" style={{ width: "50%", whiteSpace: "nowrap" }}>
									{t('preUpgrade')}: <div className="sh-num-pre-upgrade" style={{ width: "50%", marginTop: "-18px", marginLeft: "95px" }}>
										{this.countPreUpgrade(0)}
									</div>

								</div>

								<div className="sh-post-upgrade " style={{ width: "50%", whiteSpace: "nowrap", textAlign: "left" }}>
									{t('postUpgrade')}: <div classnem="sh-num-post-upgrade" style={{ width: "50%", marginTop: "-17px", color: "#71D6E3", marginLeft: "95px" }}>
										{this.countPostUpgrade(0)}
									</div>
								</div>
								<div className="sh-avg-time " style={{ width: "35%", whiteSpace: "nowrap" }}>
									{t('avgTimeInState')}: <div className="sh-time-in-state" style={{ width: "50%", marginTop: "-17px", marginLeft: "90px" }}>
										1.5d
									</div>
								</div>
								<div className="sh-historical-avg " style={{ width: "35%%", whiteSpace: "nowrap" }}>
									{t('historicalAvg')}:<div className="sh-time-historical-avg" style={{ width: "50%", marginTop: "-17px", marginLeft: "70px" }}>
										2.5d
									</div>
								</div>
								<div className="sh-horizontal-bar">

								</div>
								<div className="sh-vertical-bar">

								</div>
								<div className="sh-status-home " style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
									<div className="sh-num-status-homes" style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
										{this.countBuildingsAwaiting()}
									</div>
									<br/>
									{t('awaitingBooking')}
								</div>
							</div>


						</div>
						<div className={"status-header-container-button" + (this.state.filter == 'awaiting audit' ? " status-header-container-button-selected" : "")} onClick={() => this.clickHandler('awaiting audit')}>




							<div className="status-header" >
								<div className="sh-pre-upgrade" style={{ width: "50%", whiteSpace: "nowrap", textAlign: "left" }}>
									{t('preUpgrade')}: <div className="sh-num-pre-upgrade" style={{ width: "50%", marginTop: "-18px", marginLeft: "95px" }}>
										{this.countPreUpgrade(1)}
									</div>

								</div>

								<div className="sh-post-upgrade " style={{ width: "50%", whiteSpace: "nowrap", textAlign: "left" }}>
									{t('postUpgrade')}:<div classnem="sh-num-post-upgrade" style={{ width: "50%", color: "#71D6E3", marginTop: "-17px", marginLeft: "95px" }}>
										{this.countPostUpgrade(1)}
									</div>
								</div>
								<div className="sh-avg-time " style={{ width: "35%", whiteSpace: "nowrap" }}>
									{t('avgTimeInState')}:<div className="sh-time-in-state" style={{ width: "50%", marginTop: "-17px", marginLeft: "90px" }}>
										1.5d
									</div>
								</div>
								<div className="sh-historical-avg " style={{ width: "35%%", whiteSpace: "nowrap" }}>
									{t('historicalAvg')}:<div className="sh-time-historical-avg" style={{ width: "50%", marginTop: "-17px", marginLeft: "70px" }}>
										2.5d
									</div>
								</div>
								<div className="sh-horizontal-bar">

								</div>
								<div className="sh-vertical-bar">

								</div>
								<div className="sh-status-home " style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
									<div className="sh-num-status-homes" style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
										{this.countBuildingsBooked()}
									</div><br/>
									{t('awaitingAudit')}
								</div>
							</div>


						</div><div className={"status-header-container-button" + (this.state.filter == 'in-progress' ? " status-header-container-button-selected" : "")} onClick={() => this.clickHandler('in-progress')}>



							<div className="status-header">
								<div className="sh-pre-upgrade" style={{ width: "50%", whiteSpace: "nowrap", textAlign: "left" }}>
									{t('preUpgrade')}: <div className="sh-num-pre-upgrade" style={{ width: "50%", marginTop: "-18px", marginLeft: "95px" }}>
										{this.countPreUpgrade(2)}
									</div>

								</div>

								<div className="sh-post-upgrade " style={{ width: "50%", whiteSpace: "nowrap", textAlign: "left" }}>
									{t('postUpgrade')}:<div classnem="sh-num-post-upgrade" style={{ width: "50%", color: "#71D6E3", marginTop: "-17px", marginLeft: "95px" }}>
										{this.countPostUpgrade(2)}
									</div>
								</div>
								<div className="sh-avg-time " style={{ width: "35%", whiteSpace: "nowrap" }}>
									{t('avgTimeInState')}:<div className="sh-time-in-state" style={{ width: "50%", marginTop: "-17px", marginLeft: "90px" }}>
										1.5d
									</div>
								</div>
								<div className="sh-historical-avg " style={{ width: "35%%", whiteSpace: "nowrap" }}>
									{t('historicalAvg')}:<div className="sh-time-historical-avg" style={{ width: "50%", marginTop: "-17px", marginLeft: "70px" }}>
										2.5d
									</div>
								</div>
								<div className="sh-horizontal-bar">

								</div>
								<div className="sh-vertical-bar">

								</div>
								<div className="sh-status-home " style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
									<div className="sh-num-status-homes" style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
										{this.countBuildingsInProg()}
									</div>
									<br/>
									{t('inProgress')}
								</div>
							</div>


						</div><div className={"status-header-container-button" + (this.state.filter == 'published' ? " status-header-container-button-selected" : "")} onClick={() => this.clickHandler('published')}>


							<div className="status-header">
								<div className="sh-pre-upgrade" style={{ width: "50%", whiteSpace: "nowrap", textAlign: "left" }}>
									{t('preUpgrade')}:
									<div className="sh-num-pre-upgrade" style={{ width: "50%", marginTop: "-18px", marginLeft: "95px" }}>
										{this.countPreUpgrade(3)}
									</div>

								</div>

								<div className="sh-post-upgrade " style={{ width: "50%", whiteSpace: "nowrap", textAlign: "left" }}>
									{t('postUpgrade')}:<div classnem="sh-num-post-upgrade" style={{ width: "50%", color: "#71D6E3", marginTop: "-17px", marginLeft: "95px" }}>
										{this.countPostUpgrade(3)}
									</div>
								</div>
								<div className="sh-avg-time " style={{ width: "35%", whiteSpace: "nowrap" }}>
									{t('avgTimeInState')}:<div className="sh-time-in-state" style={{ width: "50%", marginTop: "-17px", marginLeft: "90px" }}>
										1.5d
									</div>
								</div>
								<div className="sh-historical-avg " style={{ width: "35%%", whiteSpace: "nowrap" }}>
									{t('historicalAvg')}:<div className="sh-time-historical-avg" style={{ width: "50%", marginTop: "-17px", marginLeft: "70px" }}>
										2.5d
									</div>
								</div>
								<div className="sh-horizontal-bar">

								</div>
								<div className="sh-vertical-bar">

								</div>
								<div className="sh-status-home " style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
									<div className="sh-num-status-homes" style={{ width: "150px", whiteSpace: "nowrap", textAlign: "center" }}>
										{this.countBuildingsPublished()}
									</div>
									<br/>
									{t('published')}
								</div>
							</div>

						</div>
					</div>}

					<h3 className="header" style={{ width: "100%", marginTop: "40px", marginLeft: "0px", color: "#fff", fontSize: "26px", fontWeight: "bolder" }}>
						{t('energyAdvisorHomes')}
						{!!(this.props.customers && this.props.customers.length && !this.props.isReceiving) && (
							<button
								onClick={() => {
									this.exportTable(this.props.customers);
								}}
								className="btn btn btn-primary"
								style={{ marginLeft: "15px", float: "right", marginTop: "-15px", size: "mini" }}
								type="button"
							>
								{t('exportTableToCsv')}
							</button>
						)}
					</h3>


				</div>

				<Widget fetchingData={this.props.isReceiving}>
					<BootstrapTable
						data={customerList}
						version="4"
						pagination
						options={options}
						search
						filterable
						tableContainerClass={`table-striped ${s.bootstrapTable}`}
					>
						{/*<TableHeaderColumn dataField="buildingId" isKey={true} dataFormat={this.titleFormatter} dataSort>
							<span className="fs-sm">Building</span>
							</TableHeaderColumn>*/}
						{window.innerWidth >= 768 && (
							<TableHeaderColumn dataField="fullName" dataSort dataFormat={(cell, row)=> {
								return this.nameFormatter(cell, row);
							}}>
								<span className="fs-sm">{t('name')}</span>
							</TableHeaderColumn>
						)}
						<TableHeaderColumn dataField="address" isKey={true} dataSort>
							<span className="fs-sm">{t('address')}</span>
						</TableHeaderColumn>
						{/*window.innerWidth >= 768 && (
							<TableHeaderColumn dataField="postal_code" dataSort>
								<span className="fs-sm">Postal Code</span>
							</TableHeaderColumn>
						)*/}
						{window.innerWidth >= 768 && (
							<TableHeaderColumn dataField="city" dataSort>
								<span className="fs-sm">{t('city')}</span>
							</TableHeaderColumn>
						)}
						{window.innerWidth >= 1600 && (
							<TableHeaderColumn dataField="yearBuilt" dataSort>
								<span className="fs-sm">{t('yearBuilt')}</span>
							</TableHeaderColumn>
						)}
						{window.innerWidth >= 1700 && (
							<TableHeaderColumn dataField="createdAt" dataSort dataFormat={this.createdAtFormatter}>
								<span className="fs-sm">{t('onboardedOn')}</span>
							</TableHeaderColumn>
						)}
						{window.innerWidth >= 1500 && (
							<TableHeaderColumn dataField="last_published_timestamp" dataFormat={formatDate} dataSort>
								<span className="fs-sm">
									{t('lastPublished')}
								</span>
							</TableHeaderColumn>
						)}
						{window.innerWidth >= 1400 && (
							<TableHeaderColumn dataField="last_published_fullName" dataSort>
								<span className="fs-sm">
									{t('publishedBy')}
								</span>
							</TableHeaderColumn>
						)}
						{/*window.innerWidth >= 768 && (
							<TableHeaderColumn dataField="published_report_last_downloaded_by_fullName" dataSort>
								<span className="fs-sm">
									Report Last
									<br />
									Downloaded By
								</span>
							</TableHeaderColumn>
						)*/}
						{/*window.innerWidth >= 768 && (
							<TableHeaderColumn dataField="published_report_last_downloaded_by_timestamp" dataFormat={formatDate} dataSort>
								<span className="fs-sm">
									Report Last
									<br />
									Downloaded On
								</span>
							</TableHeaderColumn>
						)*/}
						{window.IS_E1_ORG && window.innerWidth >= 768 && (
							<TableHeaderColumn width={'150px'} dataField="customerUserGroup" dataSort>
								<span className="fs-sm">{t('userGroup')}</span>
							</TableHeaderColumn>
						)}
						{window.innerWidth >= 768 && (
							<TableHeaderColumn dataField="statusText" dataSort dataFormat={this.statusFormatter}>
								<span className="fs-sm">{t('auditStatus')}</span>
							</TableHeaderColumn>
						)}
						{window.innerWidth >= 768 && (
							<TableHeaderColumn dataField="programName" dataSort dataFormat={this.statusFormatter}>
								<span className="fs-sm">{t('programName')}</span>
							</TableHeaderColumn>
						)}
						<TableHeaderColumn width={'250px'} dataFormat={this.apiFormatter}>
							<span className="fs-sm">{t('actionCaps')}</span>
						</TableHeaderColumn>
					</BootstrapTable>
				</Widget>
			</div >
		);
	}
}


function mapStateToProps(state) {
	let buildings = state.buildings.data || {};
	let statusText = state.buildings.statusText;
	return {
		customers: state.buildings.data,
		isReceiving: state.buildings.isReceiving,
		currentUser: state.auth.currentUser,
		buildings,
		statusText,
		status: buildings.audtiStatus,
		subStatus: buildings.highestAuditSubStatus || 1,
	};
}
export default withTranslation()(withRouter(connect(mapStateToProps)(EnergyAdvisor)));
