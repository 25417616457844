import React from 'react';
import ReactDOM from 'react-dom';
import {routerMiddleware} from 'connected-react-router';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux'
import ReduxThunk from 'redux-thunk'
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

import App from './components/App';
import config from './config';
import createRootReducer from './reducers';
import {doInit} from './actions/auth';
import {createHashHistory} from 'history';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import AllTranslations from '../locales/translation.json';

const history = createHashHistory();

export function getHistory() {
    return history;
}

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = "application/json";
const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(routerMiddleware(history), ReduxThunk),
)

export const store = createStore(
    createRootReducer(history),
    enhancer
);

store.dispatch(doInit());

window.MISSING_KEYS = {};

i18next.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'sessionStorage', 'navigator', 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'],
        
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        
        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
        
        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'myDomain',
        
        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement,
        
        // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
        cookieOptions: { path: '/', sameSite: 'strict' }
    },
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b'] // we need to keep <br> for line breaks
    },
    resources: AllTranslations,
    fallbackLng: 'en',
    saveMissing: true,
    saveMissingPlurals: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
        window.MISSING_KEYS[key] = fallbackValue || key;
    }
}, (err, t) => {
    console.log('i18n initialized', err);
});

document.body.style.background = 'white';

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();